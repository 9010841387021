export const metricas = {
    // Canal Maitenes
    '0222': [
        { medicion: 1, caudal: 2, porcentaje: 0 },
        { medicion: 2, caudal: 5, porcentaje: 1 },
        { medicion: 3, caudal: 11, porcentaje: 3 },
        { medicion: 4, caudal: 17, porcentaje: 4 },
        { medicion: 5, caudal: 24, porcentaje: 6 },
        { medicion: 6, caudal: 33, porcentaje: 8 },
        { medicion: 7, caudal: 42, porcentaje: 11 },
        { medicion: 8, caudal: 52, porcentaje: 13 },
        { medicion: 9, caudal: 63, porcentaje: 16 },
        { medicion: 10, caudal: 75, porcentaje: 19 },
        { medicion: 11, caudal: 87, porcentaje: 22 },
        { medicion: 12, caudal: 101, porcentaje: 25 },
        { medicion: 13, caudal: 115, porcentaje: 29 },
        { medicion: 14, caudal: 129, porcentaje: 33 },
        { medicion: 15, caudal: 145, porcentaje: 37 },
        { medicion: 16, caudal: 161, porcentaje: 41 },
        { medicion: 17, caudal: 177, porcentaje: 45 },
        { medicion: 18, caudal: 195, porcentaje: 49 },
        { medicion: 19, caudal: 212, porcentaje: 54 },
        { medicion: 20, caudal: 231, porcentaje: 58 },
        { medicion: 21, caudal: 250, porcentaje: 63 },
        { medicion: 22, caudal: 270, porcentaje: 68 },
        { medicion: 23, caudal: 290, porcentaje: 73 },
        { medicion: 24, caudal: 310, porcentaje: 78 },
        { medicion: 25, caudal: 332, porcentaje: 84 },
        { medicion: 26, caudal: 354, porcentaje: 89 },
        { medicion: 27, caudal: 376, porcentaje: 95 },
        { medicion: 28, caudal: 399, porcentaje: 101 },
        { medicion: 29, caudal: 422, porcentaje: 107 },
        { medicion: 30, caudal: 446, porcentaje: 113 },
        { medicion: 31, caudal: 470, porcentaje: 119 },
        { medicion: 32, caudal: 495, porcentaje: 125 },
        { medicion: 33, caudal: 521, porcentaje: 132 },
        { medicion: 34, caudal: 547, porcentaje: 138 },
        { medicion: 35, caudal: 573, porcentaje: 145 },
        { medicion: 36, caudal: 600, porcentaje: 152 },
        { medicion: 37, caudal: 627, porcentaje: 158 },
        { medicion: 38, caudal: 655, porcentaje: 165 },
        { medicion: 39, caudal: 683, porcentaje: 173 },
        { medicion: 40, caudal: 712, porcentaje: 180 },
        { medicion: 41, caudal: 741, porcentaje: 187 },
        { medicion: 42, caudal: 770, porcentaje: 195 },
        { medicion: 43, caudal: 800, porcentaje: 202 },
        { medicion: 44, caudal: 831, porcentaje: 210 },
        { medicion: 45, caudal: 862, porcentaje: 218 },
        { medicion: 46, caudal: 893, porcentaje: 226 },
        { medicion: 47, caudal: 925, porcentaje: 234 },
        { medicion: 48, caudal: 957, porcentaje: 242 },
        { medicion: 49, caudal: 989, porcentaje: 250 },
        { medicion: 50, caudal: 1022, porcentaje: 258 },
    ],
    // Canal Guindo
    '2433': [
        { medicion: 1, caudal: 0, porcentaje: 0 },
        { medicion: 2, caudal: 0, porcentaje: 0 },
        { medicion: 3, caudal: 1, porcentaje: 0 },
        { medicion: 4, caudal: 3, porcentaje: 1 },
        { medicion: 5, caudal: 6, porcentaje: 2 },
        { medicion: 6, caudal: 10, porcentaje: 3 },
        { medicion: 7, caudal: 14, porcentaje: 5 },
        { medicion: 8, caudal: 21, porcentaje: 7 },
        { medicion: 9, caudal: 29, porcentaje: 9 },
        { medicion: 10, caudal: 38, porcentaje: 12 },
        { medicion: 11, caudal: 49, porcentaje: 16 },
        { medicion: 12, caudal: 62, porcentaje: 20 },
        { medicion: 13, caudal: 77, porcentaje: 25 },
        { medicion: 14, caudal: 95, porcentaje: 31 },
        { medicion: 15, caudal: 114, porcentaje: 37 },
        { medicion: 16, caudal: 136, porcentaje: 44 },
        { medicion: 17, caudal: 160, porcentaje: 52 },
        { medicion: 18, caudal: 187, porcentaje: 61 },
        { medicion: 19, caudal: 216, porcentaje: 71 },
        { medicion: 20, caudal: 249, porcentaje: 81 },
        { medicion: 21, caudal: 284, porcentaje: 93 },
        { medicion: 22, caudal: 322, porcentaje: 105 },
        { medicion: 23, caudal: 363, porcentaje: 119 },
        { medicion: 24, caudal: 407, porcentaje: 133 },
        { medicion: 25, caudal: 455, porcentaje: 149 },
        { medicion: 26, caudal: 506, porcentaje: 165 },
        { medicion: 27, caudal: 560, porcentaje: 183 },
        { medicion: 28, caudal: 618, porcentaje: 202 },
        { medicion: 29, caudal: 680, porcentaje: 222 },
        { medicion: 30, caudal: 745, porcentaje: 243 },
        { medicion: 31, caudal: 814, porcentaje: 266 },
        { medicion: 32, caudal: 887, porcentaje: 290 },
        { medicion: 33, caudal: 964, porcentaje: 315 },
        { medicion: 34, caudal: 1046, porcentaje: 342 },
        { medicion: 35, caudal: 1131, porcentaje: 370 },
        { medicion: 36, caudal: 1221, porcentaje: 399 },
        { medicion: 37, caudal: 1314, porcentaje: 430 },
        { medicion: 38, caudal: 1413, porcentaje: 462 },
        { medicion: 39, caudal: 1516, porcentaje: 495 },
        { medicion: 40, caudal: 1623, porcentaje: 531 },
        { medicion: 41, caudal: 1736, porcentaje: 567 },
        { medicion: 42, caudal: 1853, porcentaje: 605 },
        { medicion: 43, caudal: 1974, porcentaje: 645 },
        { medicion: 44, caudal: 2101, porcentaje: 687 },
        { medicion: 45, caudal: 2233, porcentaje: 730 },
        { medicion: 46, caudal: 2370, porcentaje: 774 },
        { medicion: 47, caudal: 2512, porcentaje: 821 },
        { medicion: 48, caudal: 2659, porcentaje: 869 },
        { medicion: 49, caudal: 2812, porcentaje: 919 },
        { medicion: 50, caudal: 2970, porcentaje: 971 },
    ],
    // Canal Cinco Norte
    '0213': [
        { medicion: 1, caudal: 0.1, porcentaje: 0 },
        { medicion: 2, caudal: 0.5, porcentaje: 0 },
        { medicion: 3, caudal: 1.3, porcentaje: 0 },
        { medicion: 4, caudal: 2.8, porcentaje: 0 },
        { medicion: 5, caudal: 4.9, porcentaje: 1 },
        { medicion: 6, caudal: 7.7, porcentaje: 1 },
        { medicion: 7, caudal: 11.3, porcentaje: 2 },
        { medicion: 8, caudal: 15.9, porcentaje: 2 },
        { medicion: 9, caudal: 21.3, porcentaje: 3 },
        { medicion: 10, caudal: 27.8, porcentaje: 4 },
        { medicion: 11, caudal: 35.4, porcentaje: 5 },
        { medicion: 12, caudal: 44.0, porcentaje: 7 },
        { medicion: 13, caudal: 53.8, porcentaje: 8 },
        { medicion: 14, caudal: 64.9, porcentaje: 10 },
        { medicion: 15, caudal: 77.2, porcentaje: 12 },
        { medicion: 16, caudal: 90.8, porcentaje: 14 },
        { medicion: 17, caudal: 105.8, porcentaje: 16 },
        { medicion: 18, caudal: 122.1, porcentaje: 19 },
        { medicion: 19, caudal: 140.0, porcentaje: 21 },
        { medicion: 20, caudal: 159.2, porcentaje: 24 },
        { medicion: 21, caudal: 180.1, porcentaje: 27 },
        { medicion: 22, caudal: 202.4, porcentaje: 31 },
        { medicion: 23, caudal: 226.4, porcentaje: 35 },
        { medicion: 24, caudal: 252.0, porcentaje: 38 },
        { medicion: 25, caudal: 279.3, porcentaje: 43 },
        { medicion: 26, caudal: 308.3, porcentaje: 47 },
        { medicion: 27, caudal: 339.0, porcentaje: 52 },
        { medicion: 28, caudal: 371.5, porcentaje: 57 },
        { medicion: 29, caudal: 405.8, porcentaje: 62 },
        { medicion: 30, caudal: 441.9, porcentaje: 67 },
        { medicion: 31, caudal: 480.0, porcentaje: 73 },
        { medicion: 32, caudal: 519.9, porcentaje: 79 },
        { medicion: 33, caudal: 561.8, porcentaje: 86 },
        { medicion: 34, caudal: 605.6, porcentaje: 92 },
        { medicion: 35, caudal: 651.5, porcentaje: 99 },
        { medicion: 36, caudal: 699.3, porcentaje: 107 },
        { medicion: 37, caudal: 749.3, porcentaje: 114 },
        { medicion: 38, caudal: 801.3, porcentaje: 122 },
        { medicion: 39, caudal: 855.5, porcentaje: 131 },
        { medicion: 40, caudal: 911.8, porcentaje: 139 },
        { medicion: 41, caudal: 970.2, porcentaje: 148 },
        { medicion: 42, caudal: 1030.9, porcentaje: 157 },
        { medicion: 43, caudal: 1093.8, porcentaje: 167 },
        { medicion: 44, caudal: 1159.0, porcentaje: 177 },
        { medicion: 45, caudal: 1226.5, porcentaje: 187 },
        { medicion: 46, caudal: 1296.2, porcentaje: 198 },
        { medicion: 47, caudal: 1368.3, porcentaje: 209 },
        { medicion: 48, caudal: 1442.8, porcentaje: 220 },
        { medicion: 49, caudal: 1519.7, porcentaje: 232 },
        { medicion: 50, caudal: 1599.0, porcentaje: 244 },
    ],
    // Canal Cinco Centro
    '0214': [
        { medicion: 1, caudal: 3, porcentaje: 1 },
        { medicion: 2, caudal: 9, porcentaje: 2 },
        { medicion: 3, caudal: 17, porcentaje: 4 },
        { medicion: 4, caudal: 28, porcentaje: 6 },
        { medicion: 5, caudal: 40, porcentaje: 9 },
        { medicion: 6, caudal: 55, porcentaje: 12 },
        { medicion: 7, caudal: 70, porcentaje: 15 },
        { medicion: 8, caudal: 88, porcentaje: 19 },
        { medicion: 9, caudal: 107, porcentaje: 23 },
        { medicion: 10, caudal: 127, porcentaje: 27 },
        { medicion: 11, caudal: 149, porcentaje: 32 },
        { medicion: 12, caudal: 172, porcentaje: 36 },
        { medicion: 13, caudal: 196, porcentaje: 42 },
        { medicion: 14, caudal: 221, porcentaje: 47 },
        { medicion: 15, caudal: 248, porcentaje: 53 },
        { medicion: 16, caudal: 276, porcentaje: 59 },
        { medicion: 17, caudal: 305, porcentaje: 65 },
        { medicion: 18, caudal: 335, porcentaje: 71 },
        { medicion: 19, caudal: 367, porcentaje: 78 },
        { medicion: 20, caudal: 399, porcentaje: 85 },
        { medicion: 21, caudal: 433, porcentaje: 92 },
        { medicion: 22, caudal: 467, porcentaje: 99 },
        { medicion: 23, caudal: 503, porcentaje: 107 },
        { medicion: 24, caudal: 539, porcentaje: 114 },
        { medicion: 25, caudal: 577, porcentaje: 122 },
        { medicion: 26, caudal: 615, porcentaje: 131 },
        { medicion: 27, caudal: 655, porcentaje: 139 },
        { medicion: 28, caudal: 696, porcentaje: 147 },
        { medicion: 29, caudal: 737, porcentaje: 156 },
        { medicion: 30, caudal: 780, porcentaje: 165 },
        { medicion: 31, caudal: 823, porcentaje: 174 },
        { medicion: 32, caudal: 867, porcentaje: 184 },
        { medicion: 33, caudal: 912, porcentaje: 193 },
        { medicion: 34, caudal: 959, porcentaje: 203 },
        { medicion: 35, caudal: 1006, porcentaje: 213 },
        { medicion: 36, caudal: 1053, porcentaje: 223 },
        { medicion: 37, caudal: 1102, porcentaje: 234 },
        { medicion: 38, caudal: 1152, porcentaje: 244 },
        { medicion: 39, caudal: 1202, porcentaje: 255 },
        { medicion: 40, caudal: 1254, porcentaje: 266 },
        { medicion: 41, caudal: 1306, porcentaje: 277 },
        { medicion: 42, caudal: 1359, porcentaje: 288 },
        { medicion: 43, caudal: 1413, porcentaje: 300 },
        { medicion: 44, caudal: 1467, porcentaje: 311 },
        { medicion: 45, caudal: 1523, porcentaje: 323 },
        { medicion: 46, caudal: 1579, porcentaje: 335 },
        { medicion: 47, caudal: 1636, porcentaje: 347 },
        { medicion: 48, caudal: 1694, porcentaje: 359 },
        { medicion: 49, caudal: 1753, porcentaje: 372 },
        { medicion: 50, caudal: 1812, porcentaje: 384 },
    ],
    // Canal La Bruja
    '0219': [
        { medicion: 1, caudal: 5, porcentaje: 1 },
        { medicion: 2, caudal: 11, porcentaje: 2 },
        { medicion: 3, caudal: 18, porcentaje: 3 },
        { medicion: 4, caudal: 25, porcentaje: 5 },
        { medicion: 5, caudal: 32, porcentaje: 6 },
        { medicion: 6, caudal: 40, porcentaje: 8 },
        { medicion: 7, caudal: 48, porcentaje: 10 },
        { medicion: 8, caudal: 57, porcentaje: 11 },
        { medicion: 9, caudal: 65, porcentaje: 13 },
        { medicion: 10, caudal: 74, porcentaje: 15 },
        { medicion: 11, caudal: 83, porcentaje: 16 },
        { medicion: 12, caudal: 92, porcentaje: 18 },
        { medicion: 13, caudal: 102, porcentaje: 20 },
        { medicion: 14, caudal: 111, porcentaje: 22 },
        { medicion: 15, caudal: 120, porcentaje: 24 },
        { medicion: 16, caudal: 130, porcentaje: 26 },
        { medicion: 17, caudal: 140, porcentaje: 28 },
        { medicion: 18, caudal: 150, porcentaje: 30 },
        { medicion: 19, caudal: 160, porcentaje: 31 },
        { medicion: 20, caudal: 170, porcentaje: 33 },
        { medicion: 21, caudal: 180, porcentaje: 35 },
        { medicion: 22, caudal: 190, porcentaje: 38 },
        { medicion: 23, caudal: 201, porcentaje: 40 },
        { medicion: 24, caudal: 211, porcentaje: 42 },
        { medicion: 25, caudal: 222, porcentaje: 44 },
        { medicion: 26, caudal: 233, porcentaje: 46 },
        { medicion: 27, caudal: 243, porcentaje: 48 },
        { medicion: 28, caudal: 254, porcentaje: 50 },
        { medicion: 29, caudal: 265, porcentaje: 52 },
        { medicion: 30, caudal: 276, porcentaje: 54 },
        { medicion: 31, caudal: 287, porcentaje: 57 },
        { medicion: 32, caudal: 298, porcentaje: 59 },
        { medicion: 33, caudal: 309, porcentaje: 61 },
        { medicion: 34, caudal: 320, porcentaje: 63 },
        { medicion: 35, caudal: 332, porcentaje: 65 },
        { medicion: 36, caudal: 343, porcentaje: 68 },
        { medicion: 37, caudal: 354, porcentaje: 70 },
        { medicion: 38, caudal: 366, porcentaje: 72 },
        { medicion: 39, caudal: 378, porcentaje: 74 },
        { medicion: 40, caudal: 389, porcentaje: 77 },
        { medicion: 41, caudal: 401, porcentaje: 79 },
        { medicion: 42, caudal: 412, porcentaje: 81 },
        { medicion: 43, caudal: 424, porcentaje: 84 },
        { medicion: 44, caudal: 436, porcentaje: 86 },
        { medicion: 45, caudal: 448, porcentaje: 88 },
        { medicion: 46, caudal: 460, porcentaje: 91 },
        { medicion: 47, caudal: 472, porcentaje: 93 },
        { medicion: 48, caudal: 484, porcentaje: 95 },
        { medicion: 49, caudal: 496, porcentaje: 98 },
        { medicion: 50, caudal: 508, porcentaje: 100 },
    ],
    // Canal Los Paltos
    '0223': [
        { medicion: 1, caudal: 1, porcentaje: 0 },
        { medicion: 2, caudal: 3, porcentaje: 1 },
        { medicion: 3, caudal: 6, porcentaje: 2 },
        { medicion: 4, caudal: 10, porcentaje: 3 },
        { medicion: 5, caudal: 15, porcentaje: 5 },
        { medicion: 6, caudal: 20, porcentaje: 6 },
        { medicion: 7, caudal: 26, porcentaje: 8 },
        { medicion: 8, caudal: 32, porcentaje: 11 },
        { medicion: 9, caudal: 40, porcentaje: 13 },
        { medicion: 10, caudal: 48, porcentaje: 15 },
        { medicion: 11, caudal: 56, porcentaje: 18 },
        { medicion: 12, caudal: 65, porcentaje: 21 },
        { medicion: 13, caudal: 74, porcentaje: 24 },
        { medicion: 14, caudal: 84, porcentaje: 27 },
        { medicion: 15, caudal: 95, porcentaje: 31 },
        { medicion: 16, caudal: 106, porcentaje: 34 },
        { medicion: 17, caudal: 118, porcentaje: 38 },
        { medicion: 18, caudal: 130, porcentaje: 42 },
        { medicion: 19, caudal: 142, porcentaje: 46 },
        { medicion: 20, caudal: 155, porcentaje: 50 },
        { medicion: 21, caudal: 169, porcentaje: 55 },
        { medicion: 22, caudal: 183, porcentaje: 59 },
        { medicion: 23, caudal: 197, porcentaje: 64 },
        { medicion: 24, caudal: 212, porcentaje: 69 },
        { medicion: 25, caudal: 228, porcentaje: 74 },
        { medicion: 26, caudal: 243, porcentaje: 79 },
        { medicion: 27, caudal: 260, porcentaje: 84 },
        { medicion: 28, caudal: 276, porcentaje: 89 },
        { medicion: 29, caudal: 293, porcentaje: 95 },
        { medicion: 30, caudal: 311, porcentaje: 101 },
        { medicion: 31, caudal: 329, porcentaje: 106 },
        { medicion: 32, caudal: 347, porcentaje: 112 },
        { medicion: 33, caudal: 366, porcentaje: 118 },
        { medicion: 34, caudal: 385, porcentaje: 125 },
        { medicion: 35, caudal: 404, porcentaje: 131 },
        { medicion: 36, caudal: 424, porcentaje: 137 },
        { medicion: 37, caudal: 445, porcentaje: 144 },
        { medicion: 38, caudal: 465, porcentaje: 151 },
        { medicion: 39, caudal: 486, porcentaje: 157 },
        { medicion: 40, caudal: 508, porcentaje: 164 },
        { medicion: 41, caudal: 530, porcentaje: 171 },
        { medicion: 42, caudal: 552, porcentaje: 179 },
        { medicion: 43, caudal: 575, porcentaje: 186 },
        { medicion: 44, caudal: 598, porcentaje: 193 },
        { medicion: 45, caudal: 621, porcentaje: 201 },
        { medicion: 46, caudal: 645, porcentaje: 209 },
        { medicion: 47, caudal: 669, porcentaje: 217 },
        { medicion: 48, caudal: 693, porcentaje: 224 },
        { medicion: 49, caudal: 718, porcentaje: 232 },
        { medicion: 50, caudal: 744, porcentaje: 241 },
    ],

}

