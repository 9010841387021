import UserWrapper, { ModalContent } from '../../hoc/userWrapper'
import Card from '../../UI/card'
import Button from '../../UI/button'
import { Content, DetailData, Prueba, SwitchComponent } from './styles'
// import { metricas } from '../../tableLimnimeters/canal'
import { useDispatch, useSelector } from 'react-redux'
import { getLimnimeters } from '../../../store/actions/limnimeters'
import { setReadings } from '../../../store/actions/readings'
import React, { useEffect, useState, useRef } from 'react'
import { GhostLine } from '../../UI/ghostLoader'
import DatePickerWheel from '../../UI/picker/picker'
import Modal from '../../UI/modal'
import * as types from '../../../store/reducers/types'
import { useHistory } from 'react-router'

const CreateLimnimeter = () => {
    const dispatch = useDispatch()
    const listLimnimeter = useSelector(({ limnimeters }) => limnimeters)
    const notification = useSelector(({ notifications }) => notifications)
    // const loading = useSelector(({ loading }) => loading)
    const info = useSelector(({ information }) => information)
    const user = useSelector(({ user }) => user)
    const history = useHistory()
    const [listMeasurement, setListMeasurement] = useState(["Seleccione Canal"])
    const [channelError, setChannelError] = useState('');
    const [measurementError, setMeasurementError] = useState('');
    const [photoError, setPhotoError] = useState('');
    const [observationError, setObservationError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isBroken, setIsBroken] = useState(false)
    const [activeForm, setActiveForm] = useState(true)
    const [detailData, setDetailData] = useState({})
    const [formData, setFormData] = useState({
        channel: "",
        measurement: 0,
        measurementPrev: 0,
        incidents: [
            {
                id: 1,
                name: "Lamas",
                state: false
            },
            {
                id: 2,
                name: "Ramas",
                state: false
            },
            {
                id: 3,
                name: "Hojas",
                state: false
            },
            {
                id: 4,
                name: "Intrusismo",
                state: false
            },
        ],
        observation: "",
        acm_regulation: 0
    });

    const parametersList = {
        centimeters: listMeasurement
    };
    const titleList = ['Centímetros']


    const handleFormSubmit = (event) => {

        event.preventDefault()
        setIsSubmitting(true);
        let error = 0;
        if (!formData.channel) {
            setChannelError('* Debes seleccionar un canal');
            error += 1
        }
        if (!(listMeasurement.includes(formData.measurement))) {
            setMeasurementError('* Debes agregar una lectura')
            error += 1
        }
        // if (!formData.observation) {
        //     setObservationError('* Debes agregar una observación');
        //     error += 1
        // }
        let toSend = {
            ...formData,
            incidents: formData.incidents.filter(incident => incident.state === true),
            acm_regulation: info.infoCards,
            user_id: user.id
        }

        console.log(toSend)
        if (toSend.file) {
            delete toSend.file
            let form = new FormData()
            form.append('files.photo', formData.file, formData.file.name)
            form.append('data', JSON.stringify(toSend))
            toSend = form
        } else {
            error += 1
            setPhotoError('* Debes agregar una foto')
        }
        if (error === 0) {
            // Agrega consigna al Form
            setFormData({
                ...formData,
                acm_regulation: info.infoCards
            });
            dispatch(setReadings(toSend))
        } else {
            setIsSubmitting(false)
        }
    }

    /* Manejo de archivos */
    const hiddenFileInput = useRef(null)
    const handleFileClick = () => {
        hiddenFileInput.current.click()
    }

    const handleImageChange = (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            setFormData({ ...formData, file })
            const imagePreview = document.getElementById('imagePreview');
            imagePreview.src = reader.result;
        }
        reader.readAsDataURL(file)
        setPhotoError('')
    };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        // Si cambia canal obtiene la lista de lecturas
        if (name === 'channel') {
            let list = listLimnimeter.find(x => x.code === value)?.curve?.map(item => item.medicion) ?? ["Seleccione Canal"]
            setListMeasurement(list.length > 0 ? list : ['Limnímetro sin datos'])
        }
        if (name === 'incidents') {
            setFormData((prevState) => ({
                ...prevState,
                incidents: prevState.incidents.map((incident) => {
                    if (incident.name === value) {
                        return { ...incident, state: checked };
                    }
                    return incident;
                })
            }));
        } else {
            // Manejar otros campos del formulario
            setFormData({
                ...formData,
                [name]: value
            });
            name === 'channel' ? setChannelError('') : setObservationError('');
        }
    };

    // Cambia el listado de lectura
    const changePickerIndex = (e) => {
        setFormData({
            ...formData,
            measurement: e.centimeters
        })
        setMeasurementError('');
    }

    //Cambia el estado del Input y Almacena el dato de la Lectura
    const changePickerBroken = () => {
        if (!isBroken) {
            setFormData({
                ...formData,
                measurementPrev: formData.measurement,
                measurement: 1
            })
        } else {
            setFormData({
                ...formData,
                measurement: formData.measurementPrev
            })
        }
        setIsBroken(!isBroken)
    }


    const handleModalAction = () => {
        setActiveForm(false)
        dispatch({ type: types.NOTIFICATIONS, notification: false })

        // Función para manejar la Data de la vista Detalles

        let data = {}
        let reader = new FileReader()
        let file = formData.file
        reader.onloadend = () => {
            const imagePreview2 = document.getElementById('imagePreview2');
            imagePreview2.src = reader.result;
        }
        reader.readAsDataURL(file)
        data.file = file
        data.channel = listLimnimeter.find(x => x.code === formData.channel)?.name ?? 'Canal no encontrado'
        data.consigna = formData.acm_regulation
        data.consignaReal = listLimnimeter.find(channel => channel.code === formData.channel)?.curve?.find(item => item.medicion === formData.measurement).porcentaje
        data.caudal = listLimnimeter.find(channel => channel.code === formData.channel)?.curve?.find(item => item.medicion === formData.measurement).caudal
        data.measurement = formData.measurement
        data.incidents = formData.incidents.filter(incident => incident.state === true)
        data.observation = formData.observation

        setDetailData(data)
    }

    useEffect(() => {
        dispatch(getLimnimeters(user))
    }, [dispatch, user])




    useEffect(() => {
        if (isBroken) {
            setMeasurementError('');
        }
    }, [formData.channel, isBroken])

    return (
        <UserWrapper pathName='Limnímetro'>
            <Content>
                {
                    listLimnimeter && listLimnimeter.length > 0 ?
                        (
                            <Card className='stat-card'>
                                {activeForm ? (
                                    <form onSubmit={handleFormSubmit}>
                                        <div >
                                            <h3>Registrar una nueva lectura de Limnímetro</h3>
                                        </div>
                                        <div className='form-limnimeter'>
                                            <div className='form-channel'>
                                                <h5>- Seleccionar Canal (*)</h5>
                                                <select name="channel" className={channelError ? 'error' : ''}
                                                    onChange={handleInputChange} value={formData.channel}>
                                                    <option value="">Selecciona un canal</option>
                                                    {listLimnimeter.map(channel => (
                                                        <option key={channel.code} value={channel.code}>{channel.name}</option>
                                                    ))}
                                                </select>

                                            </div>
                                            {channelError && <p className="error-message">{channelError}</p>}
                                            <div className='form-measurement'>
                                                <h5>- Ingresar Lectura (*)</h5>
                                                <div className='form-measurement-content'>
                                                    <div className='form-measurement-input' >
                                                        <div className={formData.channel === "" || isBroken === true || parametersList.centimeters.length === 1 ? 'component-disabled' : ''}>
                                                            <DatePickerWheel
                                                                list={parametersList}
                                                                listTitle={titleList}
                                                                onChangePickerIndex={changePickerIndex} />
                                                        </div>
                                                    </div>
                                                    <div className='form-measurement-result'>
                                                        <h5>LECTURA</h5>
                                                        {
                                                            formData.channel === '' || isBroken === true || parametersList.centimeters.length === 1 ?
                                                                (
                                                                    <h3>-</h3>
                                                                ) : (<h3>{formData.measurement}</h3>)
                                                        }
                                                    </div>
                                                </div>
                                                <SwitchComponent>
                                                    <div className="checkbox-wrapper-2">
                                                        <div className="form-measurement-broken">
                                                            <input type="checkbox"
                                                                checked={isBroken}
                                                                onChange={changePickerBroken}
                                                                className="sc-gJwTLC ikxBAC" />
                                                            <h5>Inhabilitado</h5>
                                                        </div>
                                                    </div>
                                                </SwitchComponent>
                                            </div>
                                            {measurementError && <p className="error-message">{measurementError}</p>}
                                            <div className='form-photo'>
                                                <h5>- Agregar Foto (*)</h5>
                                                {formData.file ? (
                                                    <div className='form-photo-image'>
                                                        <div className='form-photo-image-delete'
                                                            onClick={() => {
                                                                const newForm = { ...formData }
                                                                delete newForm.file
                                                                setFormData(newForm)
                                                            }}>
                                                            <i className="fa fa-times" aria-hidden="true"></i>
                                                        </div>
                                                        <img id="imagePreview" src="" alt="Preview" />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className='form-photo-add-button'>
                                                            <div className='form-photo-icon'>
                                                                <i className="fa fa-picture-o" aria-hidden="true"></i>
                                                            </div>
                                                            <div className='form-photo-upload' onClick={() => handleFileClick()}>
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept='image/*'
                                                            capture="environment"
                                                            onChange={handleImageChange}
                                                            style={{ display: 'none' }}
                                                            ref={hiddenFileInput}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {photoError && <p className="error-message">{photoError}</p>}
                                            <div className='form-incidents'>
                                                <h5>- Seleccionar Incidencias</h5>

                                                <div className='form-incidents-flex'>
                                                    {formData.incidents.map((incident, index) => (
                                                        <div key={index}>
                                                            <Prueba>
                                                                <div>
                                                                    <p>
                                                                        <input type="checkbox"
                                                                            id={incident.name}
                                                                            name="incidents"
                                                                            value={incident.name}
                                                                            checked={incident.state}
                                                                            onChange={handleInputChange} />
                                                                        <label htmlFor={incident.name}>{incident.name}</label>
                                                                    </p>
                                                                </div>
                                                            </Prueba>


                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                            <div className='form-observation'>
                                                <h5>- Ingresar Observaciones</h5>
                                                <input name="observation" type="text" placeholder='Describa brevemente la lectura' onChange={handleInputChange} className={observationError ? 'error' : ''} />
                                                {observationError && <p className="error-message">{observationError}</p>}
                                            </div>
                                            <div className='form-button-add' >
                                                <button type='submit' disabled={isSubmitting} className={isSubmitting ? 'component-disabled' : ''}>Ingresar Nueva Lectura</button>
                                            </div>

                                        </div>
                                    </form>

                                ) : (

                                    <DetailData>
                                        <div className='detail-header'>
                                            <h3 onClick={() => {
                                                handleModalAction()
                                            }}>Detalle Lectura Registrada</h3>
                                        </div>
                                        <div className='detail-content'>
                                            <div className='detail-content-body'>
                                                <div className='detail-content-head'>
                                                    <div className='detail-content-title'>
                                                        <h3>Limnímetro {detailData.channel}</h3>
                                                    </div>
                                                </div>
                                                <div className='detail-info'>
                                                    <div className='detail-info-item'>
                                                        <div className='detail-label'>
                                                            <h3>Consigna</h3>
                                                        </div>
                                                        <div className='detail-data'>
                                                            <h3>{detailData.consigna}%</h3>
                                                        </div>
                                                    </div>
                                                    <div className='detail-info-item no-visible'>
                                                        <div className='detail-label'>
                                                            <h3>Actual</h3>
                                                        </div>
                                                        <div className='detail-data'>
                                                            <div>
                                                                <h3>{detailData.consignaReal}%</h3>
                                                                <div>
                                                                    <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='detail-info-item'>
                                                        <div className='detail-label'>
                                                            <h3>Centimetros</h3>
                                                        </div>
                                                        <div className='detail-data'>
                                                            <h3>{detailData.measurement}</h3>
                                                        </div>
                                                    </div>
                                                    <div className='detail-info-item'>
                                                        <div className='detail-label'>
                                                            <h3>Litros/Segundos</h3>
                                                        </div>
                                                        <div className='detail-data'>
                                                            <h3>{detailData.caudal}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='detail-photo'>
                                                    <div className='detail-label'>
                                                        <h3>Foto</h3>
                                                    </div>

                                                    <img id="imagePreview2" src="" alt="Preview" width="40%" />

                                                </div>
                                                <div className='detail-incidents'>
                                                    <div className='detail-label'>
                                                        <h3>Incidentes</h3>
                                                    </div>
                                                    <div className='detail-incidents-content'>
                                                        {
                                                            detailData.incidents.length > 0 ?
                                                                detailData.incidents.map((item, index) => (
                                                                    <div className='detail-incidents-data' key={index}>
                                                                        <h3>{item.name}</h3>
                                                                    </div>
                                                                ))
                                                                : <p> * Lectura sin Incidencias</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='detail-observations'>
                                                    <div className='detail-label'>
                                                        <h3>Observaciones</h3>
                                                    </div>
                                                    <div className='detail-data'>
                                                        {
                                                            detailData.observation ?

                                                                <h3>{detailData.observation}</h3> :
                                                                <p> * Lectura sin Observación</p>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='detail-content-footer'>
                                                <div className='detail-button' onClick={() => {
                                                    history.push('/panel-de-control')
                                                }}>
                                                    <button>Volver al Inicio</button>
                                                </div>
                                            </div>
                                        </div>
                                    </DetailData>
                                )}
                                {notification && notification.hasOwnProperty('message') && (
                                    <Modal>
                                        <ModalContent type='success'>
                                            <i className='fas fa-check'></i>
                                            <p>{notification.message}</p>
                                            <Button
                                                background='primary'
                                                width='100%'
                                                onClick={() => {
                                                    handleModalAction()
                                                }}
                                            >
                                                Ver Detalle
                                            </Button>
                                        </ModalContent>
                                    </Modal>
                                )}
                            </Card>
                        )
                        :
                        (
                            <Card className='stat-card'>
                                <GhostLine />
                                <GhostLine width='60%' />
                                <GhostLine width='40%' />
                            </Card>
                        )
                }

            </Content>
        </UserWrapper >
    )
}

export default CreateLimnimeter